let namespace = "workforce";
const GET_USERS_FETCH = `${namespace}/GET_USERS_FETCH`;
const GET_USERS_SUCC = `${namespace}/GET_USERS_SUCC`;
const GET_USERS_ERR = `${namespace}/GET_USERS_ERR`;

const ADD_USER_FETCH = `${namespace}/ADD_USER_FETCH`;
const ADD_USER_SUCC = `${namespace}/ADD_USER_SUCC`;
const ADD_USER_ERR = `${namespace}/ADD_USER_ERR`;

const DELETE_USER_FETCH = `${namespace}/DELETE_USER_FETCH`;
const DELETE_USER_SUCC = `${namespace}/DELETE_USER_SUCC`;
const DELETE_USER_ERR = `${namespace}/DELETE_USER_ERR`;

const UPDATE_USER_FETCH = `${namespace}/UPDATE_USER_FETCH`;
const UPDATE_USER_SUCC = `${namespace}/UPDATE_USER_SUCC`;
const UPDATE_USER_ERR = `${namespace}/UPDATE_USER_ERR`;

const GET_USER_DETAIL_FETCH = `${namespace}/GET_USER_DETAIL_FETCH`;
const GET_USER_DETAIL_SUCC = `${namespace}/GET_USER_DETAIL_SUCC`;
const GET_USER_DETAIL_ERR = `${namespace}/GET_USER_DETAIL_ERR`;

const GET_USER_TYPES_FETCH = `${namespace}/GET_USER_TYPES_FETCH`;
const GET_USER_TYPES_SUCC = `${namespace}/GET_USER_TYPES_SUCC`;
const GET_USER_TYPES_ERR = `${namespace}/GET_USER_TYPES_ERR`;

const ADD_USER_TYPES_FETCH = `${namespace}/ADD_USER_TYPES_FETCH`;
const ADD_USER_TYPES_SUCC = `${namespace}/ADD_USER_TYPES_SUCC`;
const ADD_USER_TYPES_ERR = `${namespace}/ADD_USER_TYPES_ERR`;

const GET_BANK_FETCH = `${namespace}/GET_BANK_FETCH`;
const GET_BANK_SUCC = `${namespace}/GET_BANK_SUCC`;
const GET_BANK_ERR = `${namespace}/GET_BANK_ERR`;

const ADD_BANK_FETCH = `${namespace}/ADD_BANK_FETCH`;
const ADD_BANK_SUCC = `${namespace}/ADD_BANK_SUCC`;
const ADD_BANK_ERR = `${namespace}/ADD_BANK_ERR`;

const GET_DOCUMENTS_FETCH = `${namespace}/GET_DOCUMENTS_FETCH`;
const GET_DOCUMENTS_SUCC = `${namespace}/GET_DOCUMENTS_SUCC`;
const GET_DOCUMENTS_ERR = `${namespace}/GET_DOCUMENTS_ERR`;

const ADD_DOCUMENT_FETCH = `${namespace}/ADD_DOCUMENT_FETCH`;
const ADD_DOCUMENT_SUCC = `${namespace}/ADD_DOCUMENT_SUCC`;
const ADD_DOCUMENT_ERR = `${namespace}/ADD_DOCUMENT_ERR`;

const UPDATE_DOCUMENT_FETCH = `${namespace}/UPDATE_DOCUMENT_FETCH`;
const UPDATE_DOCUMENT_SUCC = `${namespace}/UPDATE_DOCUMENT_SUCC`;
const UPDATE_DOCUMENT_ERR = `${namespace}/UPDATE_DOCUMENT_ERR`;



export default {
    GET_USERS_FETCH,
    GET_USERS_SUCC,
    GET_USERS_ERR,

    ADD_USER_FETCH,
    ADD_USER_SUCC,
    ADD_USER_ERR,

    DELETE_USER_FETCH,
    DELETE_USER_SUCC,
    DELETE_USER_ERR,

    UPDATE_USER_FETCH,
    UPDATE_USER_SUCC,
    UPDATE_USER_ERR,

    GET_USER_DETAIL_FETCH,
    GET_USER_DETAIL_SUCC,
    GET_USER_DETAIL_ERR,

    GET_USER_TYPES_FETCH,
    GET_USER_TYPES_SUCC,
    GET_USER_TYPES_ERR,

    ADD_USER_TYPES_FETCH,
    ADD_USER_TYPES_SUCC,
    ADD_USER_TYPES_ERR,

    GET_BANK_FETCH,
    GET_BANK_SUCC,
    GET_BANK_ERR,

    ADD_BANK_FETCH,
    ADD_BANK_SUCC,
    ADD_BANK_ERR,

    GET_DOCUMENTS_FETCH,
    GET_DOCUMENTS_SUCC,
    GET_DOCUMENTS_ERR,

    UPDATE_DOCUMENT_FETCH,
    UPDATE_DOCUMENT_SUCC,
    UPDATE_DOCUMENT_ERR,

    ADD_DOCUMENT_FETCH,
    ADD_DOCUMENT_SUCC,
    ADD_DOCUMENT_ERR,

    GET_USER_DETAIL: `${namespace}/GET_USER_DETAIL`,
    GET_USER_TYPES: `${namespace}/GET_USER_TYPES`,





}