// import React, { useEffect, useState } from 'react';
// import './../../../static/css/workforce.css';
// import './../../../static/css/attendance.css';
// import { useSelector,useDispatch } from 'react-redux';
// import { messageListSelector, statusSelector } from '../../../state/redux/sms/selectors'; 
import { callAddMessageApi, callGetMessagesApi, callOpStatusApi} from '../../state/redux/sms/actions'
// import { useForm } from 'react-hook-form';
// import moment from 'moment';

import React, { useEffect, useState } from 'react';
import './../../static/css/workforce.css';
import './../../static/css/attendance.css';
import { useSelector, useDispatch } from 'react-redux';
import { messageListSelector, statusSelector } from '../../state/redux/sms/selectors';
// import { callOpStatusApi } from '../../state/redux/sms/actions'
import { useForm } from 'react-hook-form';
import moment from 'moment'

const SMSCommand = ()=>{
    const dispatch = useDispatch();
    const {data: messages, total_count: totalMessages} = useSelector(messageListSelector);
    const {airtel, vodafone} = useSelector(statusSelector);
    // console.log("airtel-"+ airtel);
    // console.log("voda-"+ vodafone);
    const { register, handleSubmit, getValues, reset, formState: {errors} } = useForm();
    // const { register: register2, handleSubmit: handleSubmit2, reset: reset2 } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, getValues: getValues2, reset: reset2 } = useForm();

    const [page, setPage] = useState(1)
    const [entries, setEntries] = useState(10);
    const totalPages = Math.ceil(totalMessages/entries);

    const handleSearch = (data) => {
        setPage(1);//page
        dispatch(callGetMessagesApi({
            ...data,
            page_no: 1,//page
            limit: entries
        }));
    }

    
    // useEffect(()=>{
    //     dispatch(callGetMessagesApi({ 
    //         page_no: page,
    //         limit: entries
    //     }));
    //     dispatch(callOpStatusApi({}))
    // },[page,entries])


    const handleRefresh = ()=>{
        
       dispatch(callGetMessagesApi({
            ...getValues2(),
            page_no:page,
            limit:entries
        }));
        dispatch(callOpStatusApi({}))

    }
    const handleClearFilter = ()=>{
        // console.log("clear filter");
        reset2();
        handleRefresh();
    }

    useEffect(()=>{
        handleRefresh();
    },[page, entries])



    // useEffect(()=>{
    //     dispatch(callGetMessagesApi({
    //         ...getValues2,
    //         page_no:page,
    //         limit:entries
    //     }))
    // },[page,entries])

   

    
    // useEffect(() => {
    //     const intervalCall = setInterval(() => {
    //         dispatch(callGetMessagesApi({
    //             page_no: page,
    //             limit: entries
    //         }));
        
    //     }, 30000);
    //     return () => {
    //       // clean up
    //       clearInterval(intervalCall);
    //     };
    //   }, [page,entries]);

    const handleAddMessage = (data)=>{
        dispatch(callAddMessageApi(data));
        reset();
    }

    return(
        <>
            <div className='contentpanel'>
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className="card">

                            <div className="card-header text-start">
                                <h6>Send Message</h6>
                            </div>
                            <div className='card-body'>
                                <div className='cust-row'>
                                    <div className='cust-col-10'>
                                        <label className="black_color font-bold text-left wdt100">Device Alive Status</label>
                                        <a type="button" className="mt-2 btn btn-secondary btn-sm position-relative">
                                            Airtel
                                            <span className={"position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle " + (airtel ? "bg-success" : "bg-danger")}>
                                                <span className="visually-hidden">New alerts</span>
                                            </span>
                                        </a>
                                        <a type="button" className="mt-2 ms-3 btn btn-secondary btn-sm position-relative">
                                            Vodafone
                                            <span className={"position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle " + (vodafone ? "bg-success": "bg-danger")}>
                                                <span className="visually-hidden">New Alerts</span>
                                            </span>
                                        </a>      
                                    </div>
                                    
                                </div>
                                
                                <div>
                                    
                                </div>
                                <form  onSubmit={handleSubmit(handleAddMessage)}>
                                    <div className='cust-row'>
                                        <div className="cust-col-3">
                                            <label className="black_color font-bold text-left wdt100">Operator<span className="red_color">*</span></label>
                                            <select className='form-select'  {...register('operator', {required: true})}>
                                                <option value="">Select Operator</option>
                                                <option value="Airtel">Airtel</option>
                                                <option value="Vodafone">Vodafone</option>
                                            </select>
                                            <div className="mt-1">
                                                { errors.operator && <p className='error red_color'>Operator is mandatory</p>}
                                            </div>
                                        </div> 
                                        
                                        <div className="cust-col-3">
                                            <label className="black_color font-bold text-left wdt100">Sent To<span className="red_color">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter Mobile number" {...register('sendTo', { required: true, maxLength:13, minLength:10, pattern: /^[+]?\d+$/ })}/>
                                            <div className="mt-1">
                                                {errors.sendTo && <p className='error red_color'>Mobile number should be between 10-13 digits</p>}
                                            </div>
                                            
                                        </div>
                                        <div className="cust-col-3">
                                            <label className="black_color font-bold text-left wdt100">Message<span className="red_color">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter your message" {...register('message' , {required:true, minLength: 1})}  />
                                            <div className="mt-1">
                                                {errors.message && <p className='error red_color'>Message length should be minimum 1 character</p>}
                                            </div>
                                        </div>
                                        <div className="cust-col-1 text-center">
                                            <button className='btn btn-orange mt-3'>Send</button>
                                        </div>
                                                                             
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='card'>
                            <div className='card-header text-start'>
                                <h6>Message Logs</h6>
                            </div>
                            <div className='card-body'>
                                <div className='cust-row'>
                                    <div className='cust-col-1 flex-algn-cent'>
                                        <div className='tt2'>Filters</div>
                                    </div>
                                    <div className='cust-col-4'>
                                        <form onSubmit={handleSubmit2(handleSearch)}>
                                            <div className="input-group">
                                                <select className="form-select" {...register2('searchBy')}>
                                                    <option value="">Search By</option>
                                                    <option value="operator">Operator</option>
                                                    <option value="createdBy.userName">Sender name</option>
                                                    <option value="simNo">Mobile Number</option>
                                                </select>
                                                <input type="text" className="form-control" placeholder="Search" {...register2('search')}/>
                                                <button className="btn btn-orange">
                                                    <i className="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="cust-col-2">
                                        <div className="input-group">
                                            <label className="input-group-text">
                                                Show Entries
                                            </label>
                                            <select className="form-select" value={entries} onChange={(e) => setEntries(e.target.value)}> 
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={30}>30</option>
                                                <option value={40}>40</option>
                                                <option value={50}>50</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div className='cust-col-1 text-end'>
                                        <button className='btn btn-sm btn-orange mt-2' onClick={()=>handleClearFilter()}>Clear Filters</button>

                                    </div>
                                    <div className='cust-col-1'></div>
                                    <div className='cust-col-1 text-end'>
                                        <button className='btn btn-sm btn-orange mt-2 ' onClick={()=>handleRefresh()}><i class="fa fa-refresh" aria-hidden="true"></i></button>

                                    </div>
                                </div>
                                <table className='workforce-table mt-2'>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Mobile Number</th>
                                            <th>Messages</th>
                                            <th>Operator</th>
                                            <th>Sent By</th>
                                            <th>Sent Time</th>
                                            <th>Command Sent To Device</th>
                                            <th>Response Message</th>
                                            <th>Response Time</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            messages.length > 0
                                            ?
                                                messages.map((m,i)=>{
                                                    return(
                                                        <tr key={i}>
                                                            <td>{i+1}</td>
                                                            <td>{m.simNo?m.simNo:"-"}</td>
                                                            <td>{m.sentmessage ? getMessageParts(m.sentmessage) :"-"}</td>
                                                            <td>{m.operator?m.operator:"-"}</td>
                                                            <td>{m.createdBy ? m.createdBy.userName : "-"}</td>
                                                            <td>{m.updatedAt?moment(m.updatedAt).format("DD-MM-YYYY, h:mm:ss a"):"-"}</td>
                                                            <td>{m.messageSentAt?moment(m.messageSentAt).format("DD-MM-YYYY, h:mm:ss a"):"-"}</td>
                                                            <td>{m.receivedresponse? getMessageParts(m.receivedresponse):"-"}</td>
                                                            <td>{m.messageReceivedAt?moment(m.messageReceivedAt).format("DD-MM-YYYY, h:mm:ss a"):"-"}</td>

                                                        </tr>
                                                    )
                                                })
                                            :
                                                <tr>
                                                    <td colSpan={100}>No Records</td>
                                                </tr>
                                            
                                        }
                                    </tbody>
                                </table>
                                
                                <div className="cust-row">
                                    <div className="cust-col-5"></div>
                                    <div className="cust-col-5">
                                        <nav aria-label="Page navigation example" className="cust-pagination">
                                            <ul className="pagination justify-content-end">
                                                <li className="page-item" onClick={()=>setPage(page > 1 ? page-1: page)}>
                                                    <a className="page-link">
                                                        <i className='fa fa-caret-left'></i>
                                                    </a>
                                                </li>
                                                <li className="page-item active"><a className="page-link">{page}</a></li>
                                                <li className="page-item" onClick={()=>setPage(page < totalPages ? page+1 : page)}>
                                                    <a className="page-link">
                                                        <i className='fa fa-caret-right'></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SMSCommand;

const getMessageParts = (message) => {
    const parts = message.match(/.{1,18}/g)
    return (
        <div>
            {
                parts.map((part, i) => {
                    return <div key={i}>{part}</div>
                })
            }
        </div>
    )
}
  