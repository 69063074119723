import types from './types';

const initialState = {
    user: null,
    token: null,
    opStatus: {
        airtel: false,
        vodafone: false
    },
    messageList: {
        data: [],
        loading: false,
        total_count: 0,
        error: null
    },
    devices: {
        data: [],
        loading: false,
        total_count: 0,
        error: null
    },
    senderList: {
        data: [],
        loading: false,
        total_count: 0,
        error: null
    },
}

const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        // get messages

        case types.LOGIN_FETCH: return state
        case types.LOGIN_SUCC: return {
            ...state,
            token: action.payload.token,
            user: action.payload.data
        }
        case types.LOGIN_ERR: return {
            ...state,
            token: null,
            user: null
        }
        case types.GET_MESSAGES_FETCH: return {
            ...state,
            messageList: {
                ...state.messageList,
                loading: true,
                error: null
            }
        }
        case types.GET_MESSAGES_SUCC: return {
            ...state,
            messageList: {
                ...state.messageList,
                data: action.payload.data,
                total_count: action.payload.count,
                loading: false,
                error: null
            }
        }
        case types.GET_MESSAGES_ERR: return {
            ...state,
            messageList: {
                ...state.messageList,
                loading: false,
                data: [],
                total_count: 0,
                error: action.payload.message
            }
        }

        case types.GET_SMS_SENDER_FETCH: return {
            ...state,
            senderList: {
                loading: true,
                error: null
            }
        }
        case types.GET_SMS_SENDER_SUCC: return {
            ...state,
            senderList: {
                data: action.payload.data,
                total_count: action.payload.count,
                loading: false,
                error: null
            }
        }
        case types.GET_SMS_SENDER_ERR: return {
            ...state,
            senderList: {
                loading: false,
                data: [],
                total_count: 0,
                error: action.payload.message
            }
        }
        case types.GET_DEVICE_FETCH: return {
            ...state,
            devices: {
                loading: true,
                error: null
            }
        }
        case types.GET_DEVICE_SUCC: return {
            ...state,
            devices: {
                data: action.payload.data,
                total_count: action.payload.count,
                loading: false,
                error: null
            }
        }
        case types.GET_DEVICE_ERR: return {
            ...state,
            devices: {
                loading: false,
                data: [],
                total_count: 0,
                error: action.payload.message
            }
        }
        // add message

        case types.ADD_MESSAGE_FETCH: return {
            ...state,
            messsageList: {
                ...state.messageList,
                loading: true,
                error: null
            }
        }
        case types.ADD_MESSAGE_SUCC: return {
            ...state,
            messageList: {
                ...state.messageList,
                loading: false,
                error: null
            }
        }
        case types.ADD_MESSAGE_ERR: return {
            ...state,
            messageList: {
                ...state.messageList,
                loading: false,
                error: action.payload.message
            }
        }
        case types.GET_OP_STATUS_FETCH: return state
        case types.GET_OP_STATUS_SUCC: return {
            ...state,
            opStatus: action.payload.data
        }
        case types.GET_OP_STATUS_ERR: return {
            ...state,
            opStatus: {
                airtel: false,
                vodafone: false
            }
        }
        default: return state
    }

}

export default messageReducer;