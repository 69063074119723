let namespace = "message";
// Get SMS Sender
const GET_SMS_SENDER_FETCH = `${namespace}/GET_SMS_SENDER_FETCH`;
const GET_SMS_SENDER_SUCC = `${namespace}/GET_SMS_SENDER_SUCC`;
const GET_SMS_SENDER_ERR = `${namespace}/GET_SMS_SENDER_ERR`;

// GET Device
const GET_DEVICE_FETCH = `${namespace}/GET_DEVICE_FETCH`;
const GET_DEVICE_SUCC = `${namespace}/GET_DEVICE_SUCC`;
const GET_DEVICE_ERR = `${namespace}/GET_DEVICE_ERR`;

// ADD Device
const ADD_DEVICE_FETCH = `${namespace}/ADD_DEVICE_FETCH`;
const ADD_DEVICE_SUCC = `${namespace}/ADD_DEVICE_SUCC`;
const ADD_DEVICE_ERR = `${namespace}/ADD_DEVICE_ERR`;

// GET MESSSAGE
const GET_MESSAGES_FETCH = `${namespace}/GET_MESSAGES_FETCH`;
const GET_MESSAGES_SUCC = `${namespace}/GET_MESSAGES_SUCC`;
const GET_MESSAGES_ERR = `${namespace}/GET_MESSAGES_ERR`;

// ADD MESSAGE
const ADD_MESSAGE_FETCH = `${namespace}/ADD_MESSAGE_FETCH`;
const ADD_MESSAGE_SUCC = `${namespace}/ADD_MESSAGE_SUCC`;
const ADD_MESSAGE_ERR = `${namespace}/ADD_MESSAGE_ERR`;


export default {
    // get SMS Sender
    GET_SMS_SENDER_FETCH,
    GET_SMS_SENDER_SUCC,
    GET_SMS_SENDER_ERR,
 
    // get device
    GET_DEVICE_FETCH,
    GET_DEVICE_SUCC,
    GET_DEVICE_ERR,

    // add device
    ADD_DEVICE_FETCH,
    ADD_DEVICE_SUCC,
    ADD_DEVICE_ERR,

    // get message
    GET_MESSAGES_FETCH,
    GET_MESSAGES_SUCC,
    GET_MESSAGES_ERR,

    // add message
    ADD_MESSAGE_FETCH,
    ADD_MESSAGE_SUCC,
    ADD_MESSAGE_ERR,

    // add message
    // LOADING_SHOW,
    // LOADING_HIDE,

    LOGIN_FETCH: `${namespace}/LOGIN_FETCH`,
    LOGIN_SUCC: `${namespace}/LOGIN_SUCC`,
    LOGIN_ERR: `${namespace}/LOGIN_ERR`,

    CHANGE_PASSWORD_FETCH: `${namespace}/CHANGE_PASSWORD_FETCH`,
    CHANGE_PASSWORD_SUCC: `${namespace}/CHANGE_PASSWORD_SUCC`,
    CHANGE_PASSWORD_ERR: `${namespace}/CHANGE_PASSWORD_ERR`,

    GET_OP_STATUS_FETCH: `${namespace}/GET_OP_STATUS_FETCH`,
    GET_OP_STATUS_SUCC: `${namespace}/GET_OP_STATUS_SUCC`,
    GET_OP_STATUS_ERR: `${namespace}/GET_OP_STATUS_ERR`

}
