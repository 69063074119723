import React, {Component} from 'react';
import Footer from "../../views/Landing/Footer"
import Navbar from "../../views/Landing/Navbar"
import Login from "../../views/Landing/Login"
import './../../static/css/style.css'
import logo from './../../static/images/logo.png'

import history from '../../services/history'
import { useDispatch, useSelector } from 'react-redux';


export default function App() {
  const dispatch = useDispatch();
  const token = useSelector(state => state.message.token);
  const user = useSelector(state=>state.message.user)
 

  const _onLogin = () => {
    // localStorage.setItem('secretkey', token)
    // localStorage.setItem('user', user)
    
    history.push('/dashboard')
  }
  
  return (
    <div>
      <Navbar logo={logo}/>
      <Login _onLogin={_onLogin} />
      
      <Footer/>
    </div>
  )
}
