import React, { Component } from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import Sidebar from "../../views/Dashboard/Sidebar"
import Header from "../../views/Dashboard/Header"
import CustomBreadcrumb from "./../../views/Dashboard/CustomBreadcrumb"

import history from './../../services/history'
import Home from '../../views/Dashboard/sms';
import Main from '../../views/Main';
import Device from '../../views/Device';
import SMS_Sender from '../../views/Sms_sender';
import Sms_command from '../../views/Sms_command';
import './../../static/css/dashboard.css'
import logo from './../../static/images/logo3.png'
import $ from 'jquery'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class Dashboard extends Component 
{
  constructor(props) {
    super(props)
    this._logout = this._logout.bind(this)
  }

  componentDidMount() {
    // $("[data-toggle='dropdown']").on("click", function (e) {
    //   e.stopPropagation();
    //   let target = $(this).data('target')
    //   $(target).parent().toggleClass('open')
    // });

    // setTimeout(() => {
    //   $(".custom-modal-overlay").height("100vh");
    // }, 1000);

    // $("[data-toggle!='dropdown']").on("click", function () {
    //   $(".btn-group,.media").removeClass('open')
    // })

    // $("#menuToggle").on("click", function () {
    //   $(".sidebar").toggleClass('open')
    // })
  }

  _logout() {
    localStorage.removeItem('secretkey')
    localStorage.removeItem('user')
    history.push("/")
  }


  render() {
    return (
      <div>
        <Header handleClick={this._logout} image={logo} />
        <div className="main-content">
          <Sidebar image={logo} {...this.props} />
          <div className="content-body">
            {/* <CustomBreadcrumb {...this.props} /> */}
            <Switch>
              <Route path={`${this.props.match.url}/sms`} exact={true} render={(props)=><Home {...props}/>}/>
              <Route path={`${this.props.match.url}/main`} exact={true} render={(props)=><Main {...props}/>}/>
              <Route path={`${this.props.match.url}/device`} exact={true} render={(props)=><Device {...props}/>}/>
              <Route path={`${this.props.match.url}/sms_sender`} exact={true} render={(props)=><SMS_Sender {...props}/>}/>
              <Route path={`${this.props.match.url}/sms_command`} exact={true} render={(props)=><Sms_command {...props}/>}/>
              <Redirect to='/dashboard/main'/>
            </Switch>
          </div>
          <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />
        </div>
      </div>
    )
  }
}

export default Dashboard;