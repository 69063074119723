import React from 'react';
import { Router } from 'react-router-dom';
import history from './../services/history';
import CustomRoutes from './../routes';
import { Provider } from 'react-redux';
import store from '../state/store';


function App() {
  return (
     <Provider store={store}>
      <Router history={history}>
        <CustomRoutes />
      </Router>
    </Provider>
  );
}
export default App;