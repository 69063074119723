import types from './types';

/* Reducers for Workforce */
const initialState = {
    workforceList: {
        users: [],
        loading: false,
        error: null,
        total_users: null
    },
    userDetail:{
        user: null,
        banks: [],
        documents: [],
        loading: false,
        error: null
    },
    userTypes: [],
    userUpdate: {
        userIndex: null,
        loading: false
    }
};


const workforceReducer = (state=initialState, action) => {
    switch(action.type){
        case types.GET_USERS_FETCH: return {
            ...state,
            workforceList: {
                ...state.workforceList,
                loading: true,
                error: null
            }
        }
        case types.GET_USERS_SUCC: return {
            ...state,
            workforceList: {
                ...state.workforceList,
                users: action.payload.data,
                total_users: action.payload.count,
                loading: false,
                error: null
            }
        }
        case types.GET_USERS_ERR: return {
            ...state,
            workforceList: {
                ...state.workforceList,
                loading: false,
                users: [],
                error: action.payload.message
            }
        }
        case types.ADD_USER_FETCH : return {
            ...state,
            workforceList:{
                ...state.workforceList,
                loading:true,
                error:null
            }
        }
        case types.ADD_USER_SUCC : return {
            ...state,
            workforceList: {
                ...state.workforceList,
                loading: false,
                error: null
            }

        }
        case types.ADD_USER_ERR : return {
            ...state,
            workforceList: {
                ...state.workforceList,
                loading: false,
                error: action.payload.message
            }

        }
        case types.GET_USER_DETAIL_FETCH: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                loading: true,
                error: null
            }
        }
        case types.GET_USER_DETAIL_SUCC: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                user: action.payload.data,
                loading: false,
                error: null
            }
        }
        case types.GET_USER_DETAIL_ERR: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                loading: false,
                error: action.payload.message,
            }
        }
        case types.GET_BANK_FETCH: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                // loading: true
            }
        }
        case types.GET_BANK_SUCC: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                // loading: false,
                error: null,
                banks: action.payload.data
            }
        }
        case types.GET_BANK_ERR: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                // loading: false,
                error: action.payload,
                banks: []
            }
        }
        case types.ADD_BANK_FETCH: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                // loading: true
            }
        }
        case types.ADD_BANK_SUCC: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                // loading: false,
                error: null,
                // banks: [...state.userDetail.banks, action.payload]
            }
        }
        case types.ADD_BANK_ERR: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                // loading: false,
                error: action.payload,
                // banks: []
            }
        }
        case types.GET_DOCUMENTS_FETCH: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                // loading: true
            }
        }
        case types.GET_DOCUMENTS_SUCC: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                // loading: false,
                error: null,
                documents: action.payload.data
            }
        }
        case types.GET_DOCUMENTS_ERR: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                // loading: false,
                error: action.payload,
                documents: []
            }
        }
        case types.ADD_DOCUMENT_FETCH: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                // loading: true
            }
        }
        case types.ADD_DOCUMENT_SUCC: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                // loading: false,
                error: null,
                // banks: [...state.userDetail.banks, action.payload]
            }
        }
        case types.ADD_DOCUMENT_ERR: return {
            ...state,
            userDetail: {
                ...state.userDetail,
                // loading: false,
                error: action.payload,
                // banks: []
            }
        }
        case `${types.GET_USER_TYPES}_FETCH`: return state
        case `${types.GET_USER_TYPES}_SUCC`: return {
            ...state,
            userTypes: [...action.payload.data]
        }
        case `${types.GET_USER_TYPES}_ERR`: return state
        
        case types.ADD_USER_TYPES_FETCH: return {
            ...state,
            // userTypes: []
        }
        case types.ADD_USER_TYPES_SUCC: return {
            ...state,
            // userTypes: [...action.payload]
        }
        case types.ADD_USER_TYPES_ERR: return {
            ...state,
            // userTypes: []
        }
        default: return state
    }
}

export default workforceReducer;