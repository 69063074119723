import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import './style.css';
import './../../static/css/workforce.css';
import './../../static/css/attendance.css';
import { useSelector, useDispatch } from 'react-redux';
import { messageListSelector, statusSelector } from '../../state/redux/sms/selectors';
import { callAddDevice, callGetDevice, callOpStatusApi , callgetSmsSender } from '../../state/redux/sms/actions'
import { useForm } from 'react-hook-form';
import moment from 'moment'

const SMS_Sender = () => {
    const dispatch = useDispatch();
    const { data: messages, total_count: totalMessages } = useSelector(messageListSelector);
    const senderList = useSelector(state => state.message.senderList);
    var { data: senders, error, total_count: totalDevices } = senderList;
    const { airtel, vodafone } = useSelector(statusSelector);
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, getValues: getValues2, reset: reset2, setValue: setSearchValue } = useForm();

    const [page, setPage] = useState(1)
    const [tabType, setTabType] = useState('lynktac');
    const [entries, setEntries] = useState(10);
    const totalPages = Math.ceil(totalMessages / entries);

    const handleSearch = (data) => {
        setPage(1);
        dispatch(callgetSmsSender({
            "project": tabType,
            "searchType": "imei",
            "searchValue": data.search,
            "page": 1,
            "limit": 10
        }));
    }


    const handleRefresh = (event) => {
        setTabType(event);
        reset2();
        dispatch(callgetSmsSender({
            "project": event,
            "searchType": "imei",
            "searchValue": "",
            "page": 1,
            "limit": 10
        }));
    }
    const handleClearFilter = () => {
        reset2();
        handleRefresh(tabType);
    }

    useEffect(() => {
        handleRefresh('lynktrac');
    }, [page, entries])


    return (
        <>
            <div className='contentpanel'>
                <div className='cust-row'>
                    <div className='cust-col-10' >
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h6 className='text-center' style={{ fontWeight: '900' }}>SMS Sender Device</h6>
                                <Link to={'/main'}>
                                    <button className="btn btn-secondary">
                                        <i className="fa fa-arrow-left"></i> Back
                                    </button>
                                </Link>
                            </div>
                            <div className='card-body'>
                                <div className='cust-row'>
                                    <div className='col-12 d-flex justify-content-end'>
                                        <form onSubmit={handleSubmit2(handleSearch)}>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search by Name" {...register2('search')} />
                                                <button className="btn btn-orange">
                                                    <i className="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <table className='workforce-table mt-2' >
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Sim Number</th>
                                            <th>Total SMS</th>
                                            <th>SMS Sent to Device</th>
                                            <th>SMS Response</th>
                                            {/* <th>Status</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            senders && senders.length > 0
                                                ?
                                                senders.map((m, i) => {
                                                    return <>
                                                        {
                                                            <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{m.operator}</td>
                                                            <td>{m._id}</td>
                                                            <td>{m.totalCount}</td>
                                                            <td>{m.totalSentTrue}</td>
                                                            <td>{m.totalReceivedTrue}</td>
                                                            </tr>
                                                        }
                                                    </>
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={100}>No Records</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>


                                <div className="cust-row">
                                    <div className="cust-col-5"></div>
                                    <div className="cust-col-5">
                                        <nav aria-label="Page navigation example" className="cust-pagination">
                                            <ul className="pagination justify-content-end">
                                                <li className="page-item" onClick={() => setPage(page > 1 ? page - 1 : page)}>
                                                    <a className="page-link">
                                                        <i className='fa fa-caret-left'></i>
                                                    </a>
                                                </li>
                                                <li className="page-item active"><a className="page-link">{page}</a></li>
                                                <li className="page-item" onClick={() => setPage(page < totalPages ? page + 1 : page)}>
                                                    <a className="page-link">
                                                        <i className='fa fa-caret-right'></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SMS_Sender

