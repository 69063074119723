import { combineReducers } from 'redux'
import attendanceReducer from './redux/attendance/reducers';
import departmentReducer from './redux/department/reducers';
import workforceReducer from './redux/workforce/'
import workforceDetailReducer from './redux/workforceDetail'
import messageReducer from './redux/sms/reducers';

const rootReducer = combineReducers({
    workforce: workforceReducer,
    workforceDetail: workforceDetailReducer,
    attendance:attendanceReducer,
    department:departmentReducer,
    message:messageReducer
})

export default rootReducer;