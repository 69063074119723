import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { showToast } from '../../services/functions';
import { callLoginApi } from '../../state/redux/sms/actions';
import './../../static/css/login.css'
import bg from  './../../static/images/loginBackground.jpg'


const Login = (props) => {
    const dispatch = useDispatch();
   const {register, handleSubmit, formState: {errors}} = useForm();
   const[showPass, setShowPass] = useState(false);

   const handleLogin = (data) => {
        
        dispatch(callLoginApi(data, (res)=>{
            props._onLogin()
        }))
   }

   
    return (
        <>
            <div className='login-parent-div'>
                <img src={bg} alt=''/>
                <div className='login-left'>
                    <div className='login-div'>
                        <span>Login</span>
                        <form onSubmit={handleSubmit(handleLogin)}>
                            <div className='login-form'>
                                <div className='login-field-1'>
                                    <div className='login-img'>
                                        <i id="user_pic" class="fa fa-user" aria-hidden="true"></i>
                                    </div>


                                    <div className='email-div'>
                                        <input id="input-field1" name="userID"  type="text" placeholder="E-MAIL/ UserID" {...register('email', {required: true})}/>
                                    </div>
                                </div>

                                <div className='login-field-2'>
                                    <div className='lock-img'>
                                        <i id="lock-pic" class="fa fa-lock" aria-hidden="true"></i>
                                    </div>

                                    <div className='passwordDiv'>
                                        <input type={showPass ? "text" : "password"} name="currentPass"  id="input-field2" placeholder="PASSWORD" {...register('password', {required: true})}/>

                                    </div>
                                    <div className='eye-div'>
                                        <i  class={showPass ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true" onClick={()=> setShowPass(!showPass)}></i>
                                    </div>
                                </div>

                                <div className='login-btn-group'>
                                    <div className='btn-div'>
                                        <button>LOGIN</button>
                                        {/* <button >FORGOT PASSWORD?</button> */}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />
            </div>
            

        </>

    );
};

export default Login;



