import React from 'react';
// import { Switch, Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import Route from './Route';
import Landing from '../components/App';
import Dashboard from '../components/Dashboard';
// import SMS from '../views/Dashboard/sms';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Landing} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route component={Landing} />
    </Switch>
  );
}