import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from "../../views/Dashboard/Sidebar"
import Header from "../../views/Dashboard/Header"
import Circuit from './../../static/images/icon/mob.svg'
import OS from './../../static/images/icon/os.svg'

import './../../static/css/dashboard.css'
import logo from './../../static/images/logo3.png'
import $ from 'jquery'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class Main extends Component {
    constructor(props) {
        super(props)
        this._logout = this._logout.bind(this)
    }

    colArray = [
        {
            title: 'Devices',
            icon: 'fa fa-server',
            path: 'device'
        },
        {
            title: 'Commands',
            icon: 'fa fa-th-large',
            path: 'sms_command'
        },
        {
            title: 'Send SMS',
            icon: 'fa fa-comment',
            path: 'sms'
        },
        // {
        //     title: 'Send Command',
        //     icon: 'fa fa-info',
        //     path: 'sms'
        // },
        {
            title: 'SMS Sender Device',
            icon: 'fa fa-cog',
            path: 'sms_Sender'
        },
        // {
        //     title: 'User',
        //     icon: 'fa fa-info',
        //     path: 'sms'
        // },
    ]

    componentDidMount() {
        $("[data-toggle='dropdown']").on("click", function (e) {
          e.stopPropagation();
          let target = $(this).data('target')
          $(target).parent().toggleClass('open')
        });

        // setTimeout(() => {
        //   $(".custom-modal-overlay").height("100vh");
        // }, 1000);

        // $("[data-toggle!='dropdown']").on("click", function () {
        //   $(".btn-group,.media").removeClass('open')
        // })

        // $("#menuToggle").on("click", function () {
        //   $(".sidebar").toggleClass('open')
        // })
    }

    _logout() {
        // localStorage.removeItem('secretkey')
        // localStorage.removeItem('user')
        // history.push("/")
    }


    render() {
        return (
            <div>
                <Header handleClick={this._logout} image={logo} />
                <div className="main-content">
                    <Sidebar image={logo} {...this.props} />
                    <div className="content-body1 container" >

                    
                        <div className="x-3" >
                            <div className="card-header text-start">
                                <div className='row'>
                                    {(() => {
                                        let column = [];
                                        this.colArray.forEach((e, i) => {
                                            column.push(
                                                <div className='col-3 my-2 hvr-float-shadow' key={i}>
                                                    <Link to={e.path}>
                                                        <div className='card p-4 boxx'>
                                                            <div>
                                                                <i style={{fontSize: '20px'}} className={e.icon}></i>
                                                            </div>
                                                            <h6 >{e.title}</h6>
                                                        </div>
                                                    </Link>
                                                </div>)
                                        })
                                        return column;
                                    })()
                                    }
                                </div>
                            </div>
                        </div>
                        <img style={{position: 'absolute', opacity: 0.6, left: '3%', transform: 'rotateY(180deg)'}} src={OS} alt=''/> 
                        <img style={{position: 'absolute', opacity: 0.6, right: '8%'}} src={Circuit} alt=''/> 
                    </div>
                    <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />
                </div>
            </div>
        )
    }
}

export default Main;