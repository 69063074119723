import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux";
import { callChangePassApi } from "../../../state/redux/sms/actions";

const ChangePassword = (props) =>{
    const dispatch = useDispatch();
    const {handleSubmit, reset, register, formState:{errors},watch}= useForm();
    const newpass = watch('newPass',"")
    const oldpass = watch('oldPass')
    const confirmNewPassValidate = (val)=>{
        return val==newpass;

    }
    const newPassValidate = (val)=>{
        return val!=oldpass
    }

    const handleClick = (data)=>{
        console.log(data);
        dispatch(callChangePassApi(data,(res)=>{
            
            if(!res.error){
                reset();
                props.closeModal();
            }
            
        }))


    }
    

    return(
        <form onSubmit={handleSubmit(handleClick)}>
            <section id="changePasswordModal" className="custom-modal" style={{width:"40%"}}>
                <div className="custom-modal-header">
                <h1>Change Password</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'80vh'}}>
                    <div className="cust-row mb-2">
                        <div className="cust-col-10">
                            <label className="black_color font-bold text-left wdt100">Old Password <span className="red_color">*</span></label>
                            <input className="cust-input" type="password" placeholder="Enter Old Password" {...register('oldPass',{required:true}) }/>
                            {errors.oldPass && <p className="error red_color">Old Password is required</p>}
                        </div>
                    </div>
                    <div className="cust-row mb-2">
                        <div className="cust-col-10">
                            <label className="black_color font-bold text-left wdt100">New Password<span className="red_color">*</span></label>
                            <input className="cust-input" type="password" placeholder="Enter New Password" {...register('newPass', {required:true , validate:newPassValidate, minLength:7})}/>
                            {errors.newPass && <p className="error red_color">New Password shouldnt be same as Old password and min length is 7</p>}
                        </div>
                    </div>
                    <div className="cust-row mb-2">
                        <div className="cust-col-10">
                            <label className="black_color font-bold text-left wdt100">Confirm Password<span className="red_color">*</span></label>
                            <input className="cust-input" type="password" placeholder="Re-enter New Password" {...register("confirmPass" , {required:true , validate:confirmNewPassValidate})}/>
                            {errors.confirmPass && <p className="error red_color">Confirm Password should match with new Password</p>}

                        </div>
                    </div>
                    <div className="cust-row">
                        <div className="cust-col-2">

                        </div>
                        <div className="cust-col-6">
                            <button className='button bti bg-grey-out m-0'>
                                <div className='btn_icon'>
                                    <i className='fa fa-check'></i>
                                </div>
                                <div className='btn_txt font-lg'>Change Password</div>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default ChangePassword