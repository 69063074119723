import React, { useEffect, useState } from 'react';
import './../../../static/css/workforce.css';
import './../../../static/css/attendance.css';
import './style.css';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { messageListSelector, statusSelector } from '../../../state/redux/sms/selectors';
import { callAddMessageApi, callGetMessagesApi, callOpStatusApi, callGetDevice } from '../../../state/redux/sms/actions'
import { useForm } from 'react-hook-form';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import Loading from 'react-fullscreen-loading';

const Home = () => {
    const dispatch = useDispatch();
    const { data: messages, total_count: totalMessages,loading } = useSelector(messageListSelector);
    const devices = useSelector(state => state.message.devices);
    const { airtel, vodafone } = useSelector(statusSelector);

    var { data: deviceList, error, total_count: totalDevices } = devices;
    // console.log(deviceList,totalDevices);
    // console.log("voda-"+ vodafone);
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
    // const { register: register2, handleSubmit: handleSubmit2, reset: reset2 } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, getValues: getValues2, reset: reset2 } = useForm();

    const [page, setPage] = useState(1)
    const [updatedDeviceList, setUpdatedDeviceList] = useState([])
    const [entries, setEntries] = useState(100);
    const totalPages = totalMessages / 100;

    const [inputValue, setInputValue] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const handleSearch = (data) => {
        setPage(1);//page
        dispatch(callGetMessagesApi({
            ...data,
            page_no: 1,
            limit: entries
        }));
    }


    useEffect(() => {
        dispatch(callGetMessagesApi({
            page_no: page,
            limit: entries
        }));
    }, [entries])

    const handleRefresh = () => {

        dispatch(callGetMessagesApi({
            ...getValues2(),
            page_no: page,
            limit: 100
        }));
        dispatch(callOpStatusApi({}))
    }
    const handleClearFilter = () => {
        // console.log("clear filter");
        reset2();
        handleRefresh();
    }

    useEffect(() => {
        deviceList=[];
        handleRefresh();
        // dispatch(callGetDevice({
        //     "project": 'lynkgrid',
        //     "searchType": "imei",
        //     "searchValue": '',
        //     "page": 1,
        //     "limit": 100
        // }));
    }, []);

    useEffect(() => 
    {
        var array = [];

        deviceList && deviceList.length != 0 && deviceList.map((e) => 
        {
            if (selectedProject == 'lynktrac') 
            {
                array.push({_id:e.client_id || e.asset_id, sim:e.sim1no || "NA", sim2:e.sim2no || "NA", operator:e.sim1type || "NA", operator2:e.sim2type || "NA"})
            } 
            else if (selectedProject == 'lynkgrid') 
            {
                array.push({_id:e._id || "NA", sim:e.sim || "NA", sim2:e.sim2 || "NA", operator:e.operator || "NA", operator2:e.operator2 || "NA"})
            } 
            else 
            {
                array.push({_id:e.deviceID || "NA", sim:e.sim1 || "NA", sim2:e.sim2 || "NA", operator:e.simType1 || "NA", operator2:e.simType2 || "NA"})
            }
        });

        setSuggestions(array);

    }, [deviceList])

    const handleAddMessage = (data) => {
        dispatch(callAddMessageApi(data));
        reset();
    }

    const handleOnProjectSelect = (value) => 
    {
        dispatch(callGetDevice({
            project: selectedProject,
            searchType: "imei",
            searchValue: value,
            page: 1,
            limit: 100
        }));
    }

    const handleSuggestionClick = (value) => {
        console.log(value);
        setInputValue(value ? value._id: "");
        var arr = [];

        if(value.sim && value.sim != "NA")
        {
            arr.push({sim: value.sim, operator:value.operator })
        }

        if(value.sim2 && value.sim2 != "NA")
        {
            arr.push({sim: value.sim2, operator:value.operator2 })
        }
        setUpdatedDeviceList(arr);
        setSuggestions([]);
    };

    const handleInputChange = (event) => 
    {
        const value = event.target.value;
        
        setInputValue(value);

        if (value.length > 4) 
        {
            handleOnProjectSelect(value);
        } 
        else 
        {
            setSuggestions([]);
        }
    };

    function handlePageChange(page) {
        setPage(page.selected + 1);
        console.log(page);
        dispatch(callGetMessagesApi({
            page_no: page.selected + 1,
            limit: 100
        }));
    }

    const setProject = (e) =>
    {
        setSelectedProject(e.target.value)
    }

    return (
        <>
            <div className='contentpanel'>
                <Loading loading={loading} background="#ddebe359" loaderColor="#ff7200" />
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between" style={{
                                borderBottom: '1px solid #80808038!important'
                            }}>
                                <h6 className='text-center' style={{ fontWeight: '900' }}>Send Message</h6>
                                <Link to={'/main'}>
                                    <button className="btn btn-secondary">
                                        <i className="fa fa-arrow-left"></i> Back
                                    </button>
                                </Link>
                            </div>
                            <div className='card-body'>
                                <div className='cust-row'>
                                    <div className='cust-col-10'>
                                        <label className="black_color font-bold text-left wdt100">Device Alive Status</label>
                                        <a type="button" className="mt-2 btn btn-secondary btn-sm position-relative">
                                            Airtel
                                            <span className={"position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle " + (airtel ? "bg-success" : "bg-danger")}>
                                                <span className="visually-hidden">New alerts</span>
                                            </span>
                                        </a>
                                        <a type="button" className="mt-2 ms-3 btn btn-secondary btn-sm position-relative">
                                            Vodafone
                                            <span className={"position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle " + (vodafone ? "bg-success" : "bg-danger")}>
                                                <span className="visually-hidden">New Alerts</span>
                                            </span>
                                        </a>
                                    </div>

                                </div>

                                <div>

                                </div>
                                <form onSubmit={handleSubmit(handleAddMessage)}>
                                    <div className='cust-row'>
                                        <div className="cust-col-3">
                                            <label className="black_color font-bold text-left wdt100">Operator<span className="red_color">*</span></label>
                                            <select className='form-select'  {...register('operator', { required: true })}>
                                                <option value="">Select Operator</option>
                                                <option value="Airtel">Airtel</option>
                                                <option value="Vodafone">Vodafone</option>
                                            </select>
                                            <div className="mt-1">
                                                {errors.operator && <p className='error red_color'>Operator is mandatory</p>}
                                            </div>
                                        </div>

                                        <div className="cust-col-3">
                                            <label className="black_color font-bold text-left wdt100">Project<span className="red_color">*</span></label>
                                            <select className='form-select' value={selectedProject}  {...register('project', { required: true, onChange: (e) => { setProject(e) } })}>
                                                <option value="">Select Project</option>
                                                <option value="lynkgrid">Lynkgrid</option>
                                                <option value="lynktrac">Lynktrac</option>
                                                <option value="other">Others</option>
                                            </select>
                                            <div className="mt-1">
                                                {errors.project && <p className='error red_color'>Project is mandatory</p>}
                                            </div>
                                        </div>
                                        <div className="cust-col-3 autocomplete">
                                            <label className="black_color font-bold text-left wdt100">Device ID<span className="red_color">*</span></label>
                                            <input
                                                type="text"
                                                placeholder="Search..." {...register('deviceId', { required: true })}
                                                value={inputValue}
                                                onChange={handleInputChange}
                                                aria-autocomplete="list"
                                                aria-controls="autocomplete-list"
                                                className='form-control'
                                            />
                                            {suggestions.length > 0 &&
                                                <ul id="autocomplete-list" className="autocomplete-list" role="listbox">
                                                    {
                                                       suggestions.map((suggestion, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => handleSuggestionClick(suggestion)}
                                                            role="option"
                                                        >
                                                            {suggestion._id}
                                                        </li>
                                                    ))
                                                    }
                                                </ul>
                                            }
                                            <div className="mt-1">
                                                {errors.deviceId && <p className='error red_color'>required</p>}
                                            </div>

                                        </div>

                                        <div className="cust-col-3">
                                            <label className="black_color font-bold text-left wdt100">Sim No<span className="red_color">*</span></label>
                                            <select className='form-select'  {...register('sendTo', { required: true })}>
                                                <option value="">Select Sim No</option>
                                                {updatedDeviceList && updatedDeviceList.map((suggestion, index) => (
                                                    <option key={index} value={suggestion.sim} >{suggestion.sim} - {suggestion.operator}</option>
                                                ))
                                                }
                                            </select>

                                            <div className="mt-1">
                                                {errors.sendTo && <p className='error red_color'>Sim No is mandatory</p>}
                                            </div>
                                        </div>
                                        <div className="cust-col-3">
                                            <label className="black_color font-bold text-left wdt100">Message<span className="red_color">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter your message" {...register('message', { required: true, minLength: 1 })} />
                                            <div className="mt-1">
                                                {errors.message && <p className='error red_color'>Message length should be minimum 1 character</p>}
                                            </div>
                                        </div>
                                        <div className="cust-col-1 text-center">
                                            <button className='btn btn-orange mt-3'>Send</button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='card'>
                            <div className='card-header text-start'>
                                <h6 style={{ fontWeight: '900' }}>Message Logs</h6>
                            </div>
                            <div className='card-body'>
                                <div className='cust-row'>
                                    <div className='cust-col-1 flex-algn-cent'>
                                        <div className='tt2'>Filters</div>
                                    </div>
                                    <div className='cust-col-4'>
                                        <form onSubmit={handleSubmit2(handleSearch)}>
                                            <div className="input-group">
                                                <select className="form-select" {...register2('searchBy')}>
                                                    <option value="">Search By</option>
                                                    <option value="operator">Operator</option>
                                                    <option value="createdBy.userName">Sender name</option>
                                                    <option value="simNo">Mobile Number</option>
                                                </select>
                                                <input type="text" className="form-control" placeholder="Search" {...register2('search')} />
                                                <button className="btn btn-orange">
                                                    <i className="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="cust-col-2">
                                        <div className="input-group">
                                            <label className="input-group-text">
                                                Show Entries
                                            </label>
                                            <select className="form-select" value={entries} onChange={(e) => setEntries(e.target.value)}>
                                                <option value={100}>100</option>
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={30}>30</option>
                                                <option value={40}>40</option>
                                                <option value={50}>50</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div className='cust-col-1 text-end'>
                                        <button className='btn btn-sm btn-orange mt-2' onClick={() => handleClearFilter()}>Clear Filters</button>

                                    </div>
                                    <div className='cust-col-1'></div>
                                    <div className='cust-col-1 text-end'>
                                        <button className='btn btn-sm btn-orange mt-2 ' onClick={() => handleRefresh()}><i className="fa fa-refresh" aria-hidden="true"></i></button>

                                    </div>
                                </div>
                                <table className='workforce-table mt-2'>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Mobile Number</th>
                                            <th>Messages</th>
                                            <th>Operator</th>
                                            <th>Sent By</th>
                                            <th>Sent Time</th>
                                            <th>Command Sent To Device</th>
                                            <th>Response Message</th>
                                            <th>Response Time</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            messages.length > 0
                                                ?
                                                messages.map((m, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{((page - 1) * 100) + (i + 1)}</td>
                                                            <td>{m.simNo ? m.simNo : "-"}</td>
                                                            <td>{m.sentmessage ? getMessageParts(m.sentmessage) : "-"}</td>
                                                            <td>{m.operator ? m.operator : "-"}</td>
                                                            <td>{m.createdBy ? m.createdBy.userName : "-"}</td>
                                                            <td>{m.updatedAt ? moment(m.updatedAt).format("DD-MM-YYYY, h:mm:ss a") : "-"}</td>
                                                            <td>{m.messageSentAt ? moment(m.messageSentAt).format("DD-MM-YYYY, h:mm:ss a") : "-"}</td>
                                                            <td>{m.receivedresponse ? getMessageParts(m.receivedresponse) : "-"}</td>
                                                            <td>{m.messageReceivedAt ? moment(m.messageReceivedAt).format("DD-MM-YYYY, h:mm:ss a") : "-"}</td>

                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={100}>No Records</td>
                                                </tr>

                                        }
                                    </tbody>
                                </table>
                                <div className='cust-row mt-4'>
                                    <div className='col-12 d-flex justify-content-center'>
                                        <ReactPaginate
                                            previousLabel="Previous"
                                            nextLabel="Next"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            pageCount={totalPages}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageChange}
                                            containerClassName="pagination"
                                            activeClassName="active"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default Home

const getMessageParts = (message) => {
    const parts = message.match(/.{1,18}/g)
    return (
        <div>
            {
                parts.map((part, i) => {
                    return <div key={i}>{part}</div>
                })
            }
        </div>
    )
}
