let namespace = "attendance";
//holiday
const GET_HOLIDAYS_FETCH = `${namespace}/GET_HOLIDAYS_FETCH`;
const GET_HOLIDAYS_SUCC = `${namespace}/GET_HOLIDAYS_SUCC`;
const GET_HOLIDAYS_ERR = `${namespace}/GET_HOLIDAYS_ERR`;

const ADD_HOLIDAYS_FETCH = `${namespace}/ADD_HOLIDAYS_FETCH`;
const ADD_HOLIDAYS_SUCC = `${namespace}/ADD_HOLIDAYS_SUCC`;
const ADD_HOLIDAYS_ERR = `${namespace}/ADD_HOLIDAYS_ERR`;

const UPDATE_SETTINGS_FETCH = `${namespace}/UPDATE_SETTINGS_FETCH `;
const UPDATE_SETTINGS_SUCC = `${namespace}/UPDATE_SETTINGS_SUCC `;
const UPDATE_SETTINGS_ERR = `${namespace}/UPDATE_SETTINGS_ERR `;

// attendance
const GET_ATTENDANCE_FETCH = `${namespace}/GET_ATTENDANCE_FETCH`;
const GET_ATTENDANCE_SUCC = `${namespace}/GET_ATTENDANCE_SUCC`;
const GET_ATTENDANCE_ERR = `${namespace}/GET_ATTENDANCE_ERR`;

const ADD_ATTENDANCE_FETCH = `${namespace}/ADD_ATTENDANCE_FETCH`;
const ADD_ATTENDANCE_SUCC = `${namespace}/ADD_ATTENDANCE_SUCC`;
const ADD_ATTENDANCE_ERR = `${namespace}/ADD_ATTENDANCE_ERR`;

const UPDATE_ATTENDANCE_FETCH = `${namespace}/UPDATE_ATTENDANCE_FETCH`;
const UPDATE_ATTENDANCE_SUCC = `${namespace}/UPDATE_ATTENDANCE_SUCC`;
const UPDATE_ATTENDANCE_ERR = `${namespace}/UPDATE_ATTENDANCE_ERR`;

//expense
const GET_EXPENSE_FETCH = `${namespace}/GET_EXPENSE_FETCH`;
const GET_EXPENSE_SUCC = `${namespace}/GET_EXPENSE_SUCC`;
const GET_EXPENSE_ERR = `${namespace}/GET_EXPENSE_ERR`;

const ADD_EXPENSE_FETCH = `${namespace}/ADD_EXPENSE_FETCH`;
const ADD_EXPENSE_SUCC = `${namespace}/ADD_EXPENSE_SUCC`;
const ADD_EXPENSE_ERR = `${namespace}/ADD_EXPENSE_ERR`;

const UPDATE_EXPENSE_FETCH = `${namespace}/UPDATE_EXPENSE_FETCH`;
const UPDATE_EXPENSE_SUCC = `${namespace}/UPDATE_EXPENSE_SUCC`;
const UPDATE_EXPENSE_ERR = `${namespace}/UPDATE_EXPENSE_ERR`;

const DELETE_EXPENSE_FETCH = `${namespace}/DELETE_EXPENSE_FETCH`;
const DELETE_EXPENSE_SUCC = `${namespace}/DELETE_EXPENSE_SUCC`;
const DELETE_EXPENSE_ERR = `${namespace}/DELETE_EXPENSE_ERR`;

const GET_EXPENSE_TYPE_FETCH = `${namespace}/GET_EXPENSE_TYPE_FETCH`;
const GET_EXPENSE_TYPE_SUCC = `${namespace}/GET_EXPENSE_TYPE_SUCC`;
const GET_EXPENSE_TYPE_ERR = `${namespace}/GET_EXPENSE_TYPE_ERR`;
// payment
const GET_PAYMENT_METHOD_FETCH  = `${namespace}/GET_PAYMENT_METHOD_FETCH`;
const GET_PAYMENT_METHOD_SUCC  = `${namespace}/GET_PAYMENT_METHOD_SUCC`;
const GET_PAYMENT_METHOD_ERR  = `${namespace}/GET_PAYMENT_METHOD_ERR`;

const ADD_PAYMENT_METHOD_FETCH = `${namespace}/ADD_PAYMENT_METHOD_FETCH`;
const ADD_PAYMENT_METHOD_SUCC = `${namespace}/ADD_PAYMENT_METHOD_SUCC`;
const ADD_PAYMENT_METHOD_ERR = `${namespace}/ADD_PAYMENT_METHOD_ERR`;

// salary
const GET_SALARY_FETCH =  `${namespace}/GET_SALARY_FETCH`;
const GET_SALARY_SUCC =  `${namespace}/GET_SALARY_SUCC`;
const GET_SALARY_ERR =  `${namespace}/GET_SALARY_ERR`;


// get Leaves

const GET_USER_LEAVES_FETCH = `${namespace}/GET_USER_LEAVES_FETCH`;
const GET_USER_LEAVES_SUCC = `${namespace}/GET_USER_LEAVES_SUCC`;
const GET_USER_LEAVES_ERR = `${namespace}/GET_USER_LEAVES_ERR`;

// update leaves
const UPDATE_USER_LEAVES_FETCH = `${namespace}/UPDATE_USER_LEAVES_FETCH`;
const UPDATE_USER_LEAVES_SUCC = `${namespace}/UPDATE_USER_LEAVES_SUCC`;
const UPDATE_USER_LEAVES_ERR = `${namespace}/UPDATE_USER_LEAVES_ERR`;


// casual leaves balance
const GET_CASUAL_LEAVE_BALANCE_FETCH = `${namespace}/GET_CASUAL_LEAVE_BALANCE_FETCH`;
const GET_CASUAL_LEAVE_BALANCE_SUCC = `${namespace}/GET_CASUAL_LEAVE_BALANCE_SUCC` ;
const GET_CASUAL_LEAVE_BALANCE_ERR = `${namespace}/GET_CASUAL_LEAVE_BALANCE_ERR`;

// sick leaves balance
const GET_SICK_LEAVE_BALANCE_FETCH = `${namespace}/GET_SICK_LEAVE_BALANCE_FETCH`;
const GET_SICK_LEAVE_BALANCE_SUCC = `${namespace}/GET_SICK_LEAVE_BALANCE_SUCC` ;
const GET_SICK_LEAVE_BALANCE_ERR = `${namespace}/GET_SICK_LEAVE_BALANCE_ERR`;

// Earned leave balance
const GET_EARNED_LEAVE_BALANCE_FETCH = `${namespace}/GET_EARNED_LEAVE_BALANCE_FETCH`;
const GET_EARNED_LEAVE_BALANCE_SUCC = `${namespace}/GET_EARNED_LEAVE_BALANCE_SUCC` ;
const GET_EARNED_LEAVE_BALANCE_ERR = `${namespace}/GET_EARNED_LEAVE_BALANCE_ERR`;

// get attendance stats
const GET_ATTENDANCE_STATS_FETCH = `${namespace}/GET_ATTENDANCE_STATS_FETCH`;
const GET_ATTENDANCE_STATS_SUCC = `${namespace}/GET_ATTENDANCE_STATS_SUCC`;
const GET_ATTENDANCE_STATS_ERR = `${namespace}/GET_ATTENDANCE_STATS_ERR`;

//add leaves for user
const ADD_LEAVES_FETCH  = `${namespace}/ADD_LEAVES_FETCH`;
const ADD_LEAVES_SUCC = `${namespace}/ADD_LEAVES_SUCC`;
const ADD_LEAVES_ERR =  `${namespace}/ADD_LEAVES_ERR`;



export default {
    // get holiday
    GET_HOLIDAYS_FETCH,
    GET_HOLIDAYS_SUCC,
    GET_HOLIDAYS_ERR,

    // add holiday
    ADD_HOLIDAYS_FETCH,
    ADD_HOLIDAYS_SUCC,
    ADD_HOLIDAYS_ERR,

    // update settings for holidays
    UPDATE_SETTINGS_FETCH,
    UPDATE_SETTINGS_SUCC,
    UPDATE_SETTINGS_ERR,

    //get attendance
    GET_ATTENDANCE_FETCH,
    GET_ATTENDANCE_SUCC,
    GET_ATTENDANCE_ERR,

    // add attendance
    ADD_ATTENDANCE_FETCH,
    ADD_ATTENDANCE_SUCC,
    ADD_ATTENDANCE_ERR,

    // update attendance    
    UPDATE_ATTENDANCE_FETCH,
    UPDATE_ATTENDANCE_SUCC,
    UPDATE_ATTENDANCE_ERR,

    // get expense 
    GET_EXPENSE_FETCH,
    GET_EXPENSE_SUCC,
    GET_EXPENSE_ERR,

    // add expense
    ADD_EXPENSE_FETCH,
    ADD_EXPENSE_SUCC,
    ADD_EXPENSE_ERR,

    //update expense
    UPDATE_EXPENSE_FETCH,
    UPDATE_EXPENSE_SUCC,
    UPDATE_EXPENSE_ERR,

    //delete expense
    DELETE_EXPENSE_FETCH,
    DELETE_EXPENSE_SUCC,
    DELETE_EXPENSE_ERR,

    // get expense type
    GET_EXPENSE_TYPE_FETCH,
    GET_EXPENSE_TYPE_SUCC,
    GET_EXPENSE_TYPE_ERR,

    // get payment
    GET_PAYMENT_METHOD_FETCH,
    GET_PAYMENT_METHOD_SUCC,
    GET_PAYMENT_METHOD_ERR,

    // add payment
    ADD_PAYMENT_METHOD_FETCH,
    ADD_PAYMENT_METHOD_SUCC,
    ADD_PAYMENT_METHOD_ERR,

    // salary get
    GET_SALARY_FETCH,
    GET_SALARY_SUCC,
    GET_SALARY_ERR,

    // user leaves
    GET_USER_LEAVES_FETCH,
    GET_USER_LEAVES_SUCC,
    GET_USER_LEAVES_ERR,

    //update user leaves
    UPDATE_USER_LEAVES_FETCH,
    UPDATE_USER_LEAVES_SUCC,
    UPDATE_USER_LEAVES_ERR,
    // casual leave balance
    GET_CASUAL_LEAVE_BALANCE_FETCH,
    GET_CASUAL_LEAVE_BALANCE_SUCC,
    GET_CASUAL_LEAVE_BALANCE_ERR,

    // sick leave balance
    GET_SICK_LEAVE_BALANCE_FETCH,
    GET_SICK_LEAVE_BALANCE_SUCC,
    GET_SICK_LEAVE_BALANCE_ERR,

    // earned leave balance
    GET_EARNED_LEAVE_BALANCE_FETCH,
    GET_EARNED_LEAVE_BALANCE_SUCC,
    GET_EARNED_LEAVE_BALANCE_ERR,

    // attendance stats
    GET_ATTENDANCE_STATS_FETCH,
    GET_ATTENDANCE_STATS_SUCC,
    GET_ATTENDANCE_STATS_ERR,

    // add leaves 
    ADD_LEAVES_FETCH,
    ADD_LEAVES_SUCC,
    ADD_LEAVES_ERR,

    GET_DEFAULT_HOLIDAYS: `${namespace}/GET_DEFAULT_HOLIDAYS`,
    ADD_DEFAULT_HOLIDAY: `${namespace}/ADD_DEFAULT_HOLIDAY`,
    GET_LEAVES: `${namespace}/GET_LEAVES`,
    GET_ATTENDANCE: `${namespace}/GET_ATTENDANCE`,
    UPDATE_ATTENDANCE: `${namespace}/UPDATE_ATTENDANCE`,
    GET_ATTENDANCE_STATS: `${namespace}/GET_ATTENDANCE_STATS`,
    GET_SETTINGS: `${namespace}/GET_SETTINGS`,
    UPDATE_SETTINGS: `${namespace}/UPDATE_SETTINGS`,
    

   
}