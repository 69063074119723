import types from './types'
import axios from 'axios'
import { showToast } from '../../../services/functions'

// const domain= "http://13.234.7.240:8987";
// const domain = "http://localhost:3000"
const domain = "https://sms.lynkit.in"
// get LOGIN
export const getLoginFetch = () => {
    return {
        type: types.LOGIN_FETCH
    }
}
export const getLoginSucc = (data) => {
    return {
        type: types.LOGIN_SUCC,
        payload: data
    }
}

export const getLoginErr = (error) => {
    return {
        type: types.LOGIN_ERR,
        payload: error
    }
}

export const callLoginApi = (body, callback = null) => {
    return (dispatch) => {
        dispatch(getLoginFetch());
        axios.post(domain + '/api/v1/sendSMS/login', body).then((response) => {
            if (response.data.error) {
                dispatch(getLoginErr(response.data.message));
                showToast(response.data.message, true)

            } else {
                dispatch(getLoginSucc(response.data));
                localStorage.setItem('secretkey', response.data.token);
                localStorage.setItem('user', response.data.data.name)
                callback(response);
            }
        }).catch((error) => {
            showToast(error.message, true)
            dispatch(getLoginErr(error.message))
        })
    }
}

export const changePassFetch = () => {
    return {
        type: types.CHANGE_PASSWORD_FETCH
    }
}
export const changePassSucc = (data) => {
    return {
        type: types.CHANGE_PASSWORD_SUCC,
        payload: data
    }
}

export const changePassErr = (error) => {
    return {
        type: types.CHANGE_PASSWORD_ERR,
        payload: error
    }
}

export const callChangePassApi = (body, callback = null) => {
    return (dispatch) => {
        dispatch(changePassFetch());
        axios.post(domain + '/api/v1/sendSMS/change-password', body, {
            headers: {
                'authorization': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            if (response.data.error) {
                dispatch(changePassErr(response.data.message));
                showToast(response.data.message, true)

            } else {
                dispatch(changePassSucc(response.data));
                showToast(response.data.message, false)
                if (callback) {
                    callback(response);
                }

            }
        }).catch((error) => {
            showToast(error.message, true)
            dispatch(changePassErr(error.message))
        })
    }
}


// get messages
export const getMessagesFetch = () => {
    return {
        type: types.GET_MESSAGES_FETCH
    }
}
export const getMessagesSucc = (data) => {
    return {
        type: types.GET_MESSAGES_SUCC,
        payload: data
    }
}

export const getMessagesErr = (error) => {
    return {
        type: types.GET_MESSAGES_ERR,
        payload: error
    }
}

export const callGetMessagesApi = (body) => {
    return (dispatch) => {
        dispatch(getMessagesFetch());
        axios.post(domain + '/api/v1/sendSMS/get-message', body, {
            headers: {
                'authorization': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            if (response.data.error) {
                dispatch(getMessagesErr(response.data.message))
            } else {
                dispatch(getMessagesSucc(response.data))
            }

        }).catch((error) => {
            dispatch(getMessagesErr(error.message))
        })
    }

}

// add  message

export const addMessageFetch = () => {
    return {
        type: types.ADD_MESSAGE_FETCH
    }
}

export const addMessageSucc = (data) => {
    return {
        type: types.ADD_MESSAGE_SUCC,
        payload: data
    }
}

export const addMessageErr = (error) => {
    return {
        type: types.ADD_MESSAGE_ERR,
        payload: error
    }
}
export const callAddMessageApi = (body) => {
    return (dispatch) => {
        dispatch(addMessageFetch());
        axios.post(domain + '/api/v1/sendSMS/send-message', body, {
            headers: {
                'authorization': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            if (response.data.error) {
                dispatch(addMessageErr(response.data.message))
            } else {
                dispatch(addMessageSucc(response.data));
                showToast(response.data.message, false);
                dispatch(callGetMessagesApi({}));
            }

        }).catch((error) => {
            dispatch(addMessageErr(error.message))
        })
    }
}

// add  message

export const getOpStatusFetch = () => {
    return {
        type: types.GET_OP_STATUS_FETCH
    }
}

export const getOpStatusSucc = (data) => {
    return {
        type: types.GET_OP_STATUS_SUCC,
        payload: data
    }
}

export const getOpStatusErr = (error) => {
    return {
        type: types.GET_OP_STATUS_ERR,
        payload: error
    }
}
export const callOpStatusApi = (body) => {
    return (dispatch) => {
        dispatch(getOpStatusFetch());
        axios.post(domain + '/api/v1/sendSMS/operator-status', body, {
            headers: {
                'authorization': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            if (response.data.error) {
                dispatch(getOpStatusErr(response.data.message))
                showToast(response.data.message, true)
            } else {
                dispatch(getOpStatusSucc(response.data));
            }

        }).catch((error) => {
            dispatch(getOpStatusErr(error.message))
            showToast("Session expired. Please logout and Login again", true)
        })
    }
}




// get SMS Sender
export const getSmsSenderFetch = () => {
    return {
        type: types.GET_SMS_SENDER_FETCH
    }
}
export const getSmsSenderSucc = (data) => {
    return {
        type: types.GET_SMS_SENDER_SUCC,
        payload: data
    }
}

export const getSmsSenderErr = (error) => {
    return {
        type: types.GET_SMS_SENDER_ERR,
        payload: error
    }
}

export const callgetSmsSender = (body) => {
    return (dispatch) => {
        dispatch(getSmsSenderFetch());
        axios.post(domain + '/api/v1/sendSMS/get-sms-sender', body, {
            headers: {
                'authorization': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            if (response.data.error) {
                dispatch(getSmsSenderErr(response.data.message))
            } else {
                dispatch(getSmsSenderSucc(response.data))
            }

        }).catch((error) => {
            dispatch(getSmsSenderErr(error.message))
        })
    }

}

// add Device
export const addDeviceFetch = () => {
    return {
        type: types.GET_DEVICE_FETCH
    }
}
export const addDeviceSucc = (data) => {
    return {
        type: types.GET_DEVICE_SUCC,
        payload: data
    }
}

export const addDeviceErr = (error) => {
    return {
        type: types.GET_DEVICE_ERR,
        payload: error
    }
}

export const callAddDevice = (body) => {
    return (dispatch) => {
        dispatch(addDeviceFetch());
        axios.post(domain + '/api/v1/sendSMS/add-device', body, {
            headers: {
                'authorization': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            if (response.data.error) {
                dispatch(addDeviceErr(response.data.message))
            } else {
                dispatch(addDeviceSucc(response.data))
            }

        }).catch((error) => {
            dispatch(addDeviceErr(error.message))
        })
    }

}

// get Device
export const getDeviceFetch = () => {
    return {
        type: types.GET_DEVICE_FETCH
    }
}
export const getDeviceSucc = (data) => {
    return {
        type: types.GET_DEVICE_SUCC,
        payload: data
    }
}

export const getDeviceErr = (error) => {
    return {
        type: types.GET_DEVICE_ERR,
        payload: error
    }
}

// export const setLoading = (error) => {
//     return {
//         type: types.LOADING_SHOW
//     }
// }

// export const hideLoading = (error) => {
//     return {
//         type: types.LOADING_HIDE
//     }
// }

export const callGetDevice = (body) => {
    return (dispatch) => {
        dispatch(getDeviceFetch());

        body.selection = [
            "client_id",
            "asset_id",
            "packet_time",
            "geo_name",
            "lockStatus",
            "pswd",
            "speed",
            "tripId",
            "status",
            "adminName",
            "orgName",
            "device_status",
            "latitude",
            "longitude",
            "_id",
            "formData.viewpasscode",
            "device_name",
            "deviceModel",
            "deviceType",
            "sim1type",
            "sim1no",
            "sim2type",
            "sim2no"
        ]

        body.filter = {
            // "deviceType": "elock",
            // "user": "65fa7147cd64044f63dd3a5b",
            "status": "ACTIVE"
        }
        // dispatch(setLoading());
        axios.post(domain + '/api/v1/sendSMS/get-device', body, {
            headers: {
                'authorization': localStorage.getItem('secretkey')
            }

        }).then((response) => {
            // dispatch(hideLoading());
            if (response.data.error) {
                dispatch(getDeviceErr(response.data.message))
            } else {
                dispatch(getDeviceSucc(response.data))
            }

        }).catch((error) => {
            // dispatch(hideLoading());
            dispatch(getDeviceErr(error.message))
        })
    }

}