import types from './types';

const initialState ={
   departmentList:{
        department:[],
        loading:false,
        error:null
        
   }
}

const departmentReducer = (state=initialState, action)=>{
    switch(action.type){
        case types.GET_DEPARTMENTS_FETCH : return {
            ...state,
            departmentList:{
                ...state.departmentList,
                loading:true,
                error: null
            }
        }
         case types.GET_DEPARTMENTS_SUCC : return {
            ...state,
            departmentList: {
                ...state.departmentList,
                department: action.payload.data,
                loading: false,
                error: null
            }
         }
         case types.GET_DEPARTMENTS_ERR : return {
            ...state,
            departmentList:{
                ...state.departmentList,
                loading:false,
                error:action.payload.message
            }
         }
         // add department

        case types.ADD_DEPARTMENT_FETCH : return {
            ...state,
            departmentList:{
                ...state.departmentList,
                loading:true,
                error: null
            }
        }
         case types.ADD_DEPARTMENT_SUCC : return {
            ...state,
            departmentList: {
                ...state.departmentList,
                // department: action.payload.data,
                loading: false,
                error: null
            }
         }
         case types.ADD_DEPARTMENT_ERR : return {
            ...state,
            departmentList:{
                ...state.departmentList,
                loading:false,
                error:action.payload.message
            }
         }
          default: return state
    }

}

export default departmentReducer;