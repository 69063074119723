import React from 'react';
import { Link } from 'react-router-dom';
import logo from './../../static/images/logo.png'
import history from './../../services/history';
import PasswordModal from './Modal/ChangePassword'
import $ from 'jquery'
import { useSelector, useDispatch } from "react-redux";

import{getLoginSucc} from '../../state/redux/sms/actions';

const Header = (props) => {
    const user = localStorage.getItem('user')
    console.log("user"+user);
    // const user = useSelector(state => state.message.user)

    const changeRoute = () => {
        history.push('/dashboard/userProfile')
    }

    const _openModal = (id) =>{
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }

    return (
        <header>
            <PasswordModal closeModal={_closeModal}/>
            <div className="headerpanel">

                <div className="logopanel">
                    <h2><Link to="/dashboard"><img src={logo} alt="logo" /></Link></h2>
                </div>

                <div className="headerbar">

                    {/* <a id="menuToggle" className="menutoggle" href="abcd" onClick={(event) => event.preventDefault()}>
                        <i className="fa fa-bars"></i>
                    </a> */}
                    <div className="header-right">
                        <ul className="headermenu">
                            <li>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-logged" data-toggle="dropdown" data-target="#headdp" aria-expanded="false">
                                        <img src={props?.image} alt="" />
                                        <div className='udeta'>
                                            {/* <span className='n1'>User</span> */}

                                            <span className='n1'>{user}</span>
                                            {/* <span className='n2'>ram@lynkit.in</span>
                                            <span className='n3'>Admin</span> */}
                                        </div>
                                        <span className="caret"></span>
                                    </button>
                                    <div className="user-dropmenu" id="headdp">
                                        <ul>
                                            {/* <li onClick={changeRoute} ><i className="fa fa-user" aria-hidden="true"></i> Profile</li>
                                            
                                            <li><i className="fa fa-cog" aria-hidden="true"></i> Setting</li> */}
                                            
                                            <li onClick={()=>{_openModal('changePasswordModal')}} ><i className="fa fa-key" aria-hidden="true"></i> Change Password</li>
                                            <li onClick={props.handleClick}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <PasswordModal closeModal={_closeModal}/>
        </header>
    )
};

export default Header;