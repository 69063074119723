import types from './types'
const initialState = {
    expenseTypes: [],
    expenses: [],
    paymentMethods: [],
    monthSalary: {
        "salary": 0,
        "present": 0,
        "holidays": 0,
        "leaves": 0,
        "payableDays": 0,
        "absent": 0
    },
    leaveBalance: {
        Casual: null,
        Earned: null,
        Sick: null
    },
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case `${types.GET_EXPENSE_TYPE}_FETCH`: return state
        case `${types.GET_EXPENSE_TYPE}_SUCC`: return {
            ...state,
            expenseTypes: action.payload.data
        }
        case `${types.GET_EXPENSE_TYPE}_ERR`: return state

        case `${types.ADD_EXPENSE_TYPE}_FETCH`: return state
        case `${types.ADD_EXPENSE_TYPE}_SUCC`: return state
        case `${types.ADD_EXPENSE_TYPE}_ERR`: return state

        ///

        case `${types.GET_EXPENSE}_FETCH`: return state
        case `${types.GET_EXPENSE}_SUCC`: return {
            ...state,
            expenses: action.payload.data
        }
        case `${types.GET_EXPENSE}_ERR`: return state

        case `${types.ADD_EXPENSE}_FETCH`: return state
        case `${types.ADD_EXPENSE}_SUCC`: return state
        case `${types.ADD_EXPENSE}_ERR`: return state

        // Payment method API

        case `${types.GET_PAYMENT_METHOD}_FETCH`: return state
        case `${types.GET_PAYMENT_METHOD}_SUCC`: return {
            ...state,
            paymentMethods: action.payload.data
        }
        case `${types.GET_PAYMENT_METHOD}_ERR`: return state

        case `${types.ADD_EXPENSE_TYPE}_FETCH`: return state
        case `${types.ADD_EXPENSE_TYPE}_SUCC`: return state
        case `${types.ADD_EXPENSE_TYPE}_ERR`: return state

        case `${types.GET_SALARY}_FETCH`: return state
        case `${types.GET_SALARY}_SUCC`: return {
            ...state,
            monthSalary: action.payload.data
        }
        case `${types.GET_SALARY}_ERR`: return state

         // get causal leave balance
         case `${types.GET_LEAVE_BALANCE}_FETCH`: return state
         case `${types.GET_LEAVE_BALANCE}_SUCC`: 
             let newLeaveBalance = {...state.leaveBalance}   
             newLeaveBalance[action.payload.data[0].leaveType] = action.payload.data[0].leaveCount
             return {
                 ...state,
                 leaveBalance: newLeaveBalance
             }
         case `${types.GET_LEAVE_BALANCE}_ERR`: return state
        

        default: return state
    }
}

export default reducer;