import React,{Component} from 'react'
import {Link} from 'react-router-dom';
import history from './../../services/history';

//InActive
import AddressIcon from './../../static/images/icon/sidebar/updated/address_book.svg'
import AddressActiveIcon from './../../static/images/icon/sidebar/updated/address_book_active.svg'


class Sidebar extends Component{
    constructor(props){
        super(props)
        this.state={
            url:`${props.location.pathname}${props.location.search}${props.location.hash}`
        }
    }

    componentDidMount() {
        this.unlisten = history.listen((location, action) => {
            this.setState({
                url:`${location.pathname}${location.search}${location.hash}`
            })
        })
    }

    componentWillUnmount() {
        this.unlisten();
    }  

    render() {
      return (
          <div className="sidebar ">
              <div className="tab-content">
                  <div className="tab-pane" id="mainmenu">
                      <ul className="menu-nav">
                    

                        <li className={this.state.url.includes("/dashboard/sms")?"nav-parent active":"nav-parent"}>
                            <Link to="/dashboard/sms">
                                <img className='sicon' src={this.state.url.includes("/dashboard/booking")?AddressActiveIcon:AddressIcon} alt=''/>
                                <span className='txt'>SMS</span>
                            </Link>
                        </li>
                    </ul>
                  </div>
              </div>
          </div>
      )
    }
}

export default Sidebar;